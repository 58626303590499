import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import PlainTextLayout from './PlainTextLayout';

const NFTManifestoSection = () => {
  const { t } = useTranslation();

  return (
    <PlainTextLayout
      title={t('NFT Manifesto')}
      id="manifesto"
      content={[
        t(
          "Today the world of blockchain gaming is in a similar place to where blockchain finance was a number of years ago. The current landscape of the space is not focused on building innovative & trustless Web3 gaming, but insteadfocusing effort on 'duct-taping' NFTs and tokens onto classical Web2 gaming stacks (aka. Web2.5 gaming)."
        ),
        t(
          "These Web2.5 gaming projects incorporate small elements of blockchain technology, however are still fully dependent on the game development studio to run centralized private servers for the game to function at all. If the company goes out of business, an employee goes rogue, or they are explicitly hacked, then the entire game can go offline and all of the user data can disappear potentially forever. What's worse, on top of inheriting all of the negative tradeoffs of the Web2 stack, Web2.5 gaming primarily only offers players a sub-par gaming experience and little upside for the everyday player."
        ),
        t(
          'The truth is that the model of Web2.5 gaming is about as technologically innovative as centralized exchanges, leaving both devs and players uninterested overall. This has been proven by the lack of significant mainstream adoption of Web2.5 games. In fact, looking at current iterations of the Web2.5 gaming model, these games primarily only attract speculators, leading to extremely short lifespans for the projects themselves with no longevity to build something that will last.'
        ),
        t(
          'At Paima Studios our mission is to move the blockchain gaming industry from centralized Web2.5 gaming, to a new era of trustless Web3 gaming. Just like how DeFi was built by an ecosystem focused on shipping trustless financial primitives, we believe that the future of blockchain gaming will be built by an ecosystem focused on shipping trustless gaming primitives. Trustless Web3 gaming is the future of blockchain gaming.'
        ),
        t(
          'To bring this vision into reality, we have spent numerous months on research in discovering novel trustless gaming primitives (with significant inspiration from L2/rollup technologies) which has culminated in the development of Paima Engine.'
        ),
        t(
          'Paima Engine is our answer to the current state of blockchain gaming. It is the very first Trustless Web3 Gaming engine, which enables game developers to build their own blockchain games as full-fledged L2s (Layer 2s). In other words, Paima Engine allows game devs to implement their games in a language they are familiar with (Typescript/Javascript), while hiding away the vast majority of complexity of blockchain integration, yet still utilizing the most bleeding-edge technology currently available in the industry.'
        ),
        t(
          'Games built with Paima Engine can be understood as trustless & open blockchain protocols which take take advantage of the latest in L2 scaling technology. Furthermore, by going in this completely novel direction, Paima Engine is also pioneering newly discovered trustless faming primitives which have never been possible before in the Web2.5 model, such as Stateful NFTs.'
        ),
        t(
          'At Paima Studios, we believe innovation is a core requirement for long-term success of any new technology. Paima Engine is at the forefront of Trustless Web3 Gaming and will provide developers (both small indie teams, and large studios) the ability to jump into the new era of blockchain gaming with as little friction as possible.'
        ),
        t(
          'We are excited to be pushing the boundaries of blockchain gaming with all of you, and look forward to seeing just how far Trustless Web3 Gaming will go in the years to come.'
        )
      ]}
      bottomNote={t('Paima Team')}
    />
  );
};

export default NFTManifestoSection;
