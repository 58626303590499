import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import CookieConsentBanner from '../components/CookieConsentBanner';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import NFTManifestoSection from '../components/v2/NFTManifestoSection';

const Manifesto = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Paima Studios Manifesto')}>
      <Header />
      <CookieConsentBanner />
      <NFTManifestoSection />
      <Footer />
    </Layout>
  );
};

export default Manifesto;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
